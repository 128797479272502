






import Vue from 'vue'
import Menu from "@/components/Menu.vue";
export default Vue.extend({
  components: {
    Menu
  }
})
