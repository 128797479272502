

































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Menu extends Vue {
  private isOpen = false;

  closeMenu() {
    this.isOpen = false;
  }
}
